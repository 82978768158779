import { useOnClickEffect } from '../../../../hooks';
import {
  AuthenticationResetProps,
  AuthenticationResetState,
  AuthenticationResetHandlers,
} from '../../..';

export function useAuthenticationResetEffect(
  props: AuthenticationResetProps
): [AuthenticationResetState, AuthenticationResetHandlers] {
  const classes = props.styleClasses.join(' ');

  const state: AuthenticationResetState = {
    classes,
  };

  const [onLogin] = useOnClickEffect({
    id: props.id,
    name: props.id,
    onClickHandler: props.onLoginHandler,
    preventDefault: true,
  });

  const handlers: AuthenticationResetHandlers = {
    onLogin,
  };

  return [state, handlers];
}

import {
  useAuthenticationLoginEffect,
  AuthenticationLoginProps,
  AuthenticationLoginTemplateProps,
  AuthenticationLoginDefaultTemplate,
} from '../../..';

export const AuthenticationLoginSegment = (props: AuthenticationLoginProps) => {
  const [state, handlers] = useAuthenticationLoginEffect(props);

  const templateProps: AuthenticationLoginTemplateProps = {
    id: props.id,
    form: props.form,
    isLoading: props.isLoading,
    welcomeMessage: props.welcomeMessage,
    hasResetPassword: props.hasResetPassword,
    serverError: props.serverError,
    regionLink: props.regionLink,
    ...state,
    ...handlers,
  };

  let template;
  switch (props.template) {
    case 'authenticationLoginDefault':
    default:
      template = AuthenticationLoginDefaultTemplate(templateProps);
      break;
  }

  return template;
};

import { AuthenticationProps, AuthenticationState } from '../../..';

export function useAuthenticationEffect(
  props: AuthenticationProps
): [AuthenticationState] {
  const { styleClasses } = props;

  const classes = styleClasses.join(' ');

  const state: AuthenticationState = { classes };

  return [state];
}

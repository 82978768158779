import { useOnClickEffect } from '../../../../hooks';
import {
  AuthenticationPasswordProps,
  AuthenticationPasswordState,
  AuthenticationPasswordHandlers,
} from '../../..';

export function useAuthenticationPasswordEffect(
  props: AuthenticationPasswordProps
): [AuthenticationPasswordState, AuthenticationPasswordHandlers] {
  const classes = props.styleClasses.join(' ');

  const state: AuthenticationPasswordState = {
    classes,
  };

  const [onLogin] = useOnClickEffect({
    id: props.id,
    name: props.id,
    onClickHandler: props.onLoginHandler,
    preventDefault: true,
  });

  const handlers: AuthenticationPasswordHandlers = {
    onLogin,
  };

  return [state, handlers];
}

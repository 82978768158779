import React from 'react';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';
import { AuthenticationLoginTemplateProps } from '../interfaces';
import { AuthenticationLoginStyled } from '../styles';
import {
  ButtonInputElement,
  IconElement,
  TextInputElement,
} from '../../../elements';

export const AuthenticationLoginDefaultTemplate = (
  props: AuthenticationLoginTemplateProps
) => {
  if (!props.form) {
    throw new UnacceptableValueException(
      'Could not load authentication login template: Missing form'
    );
  }

  return (
    <AuthenticationLoginStyled className={props.classes}>
      <div className="authentication-title-content">
        <h2>{props.welcomeMessage}</h2>
      </div>
      <div className="authentication-body-content">
        {props.serverError && (
          <div className="authentication-server-error">
            <div className="authentication-server-error-icon">
              <IconElement
                template="iconExclamationReact"
                styleClasses={['iconBase']}
              />
            </div>
            <div className="authentication-server-error-content">
              <div className="authentication-server-error-message">
                {props.serverError}
              </div>
              {props.regionLink && (
                <div
                  className="authentication-region-link"
                  onClick={() => props.regionLink?.onLoginHandler()}
                >
                  &nbsp;Continue to {props.regionLink.label} login
                </div>
              )}
            </div>
          </div>
        )}
        {!props.isLoading && !props.serverError && props.hasResetPassword && (
          <div className="authentication-password-success">
            <div className="authentication-password-success-icon">
              <IconElement
                template="iconCheckCircle"
                styleClasses={['iconBase']}
              />
            </div>
            <div className="authentication-password-success-content">
              Password successfully reset
            </div>
          </div>
        )}
        <form>
          <TextInputElement {...props.form.emailInputProps} />
          <TextInputElement {...props.form.passwordInputProps} />
          <div className="button-container">
            <ButtonInputElement {...props.form.submitInputProps} />
          </div>
        </form>
      </div>
      <p className="authentication-link-content">
        <a onClick={props.onForgotPassword}>Forgot password?</a>
      </p>
    </AuthenticationLoginStyled>
  );
};

import React from 'react';
import { AuthenticationStyled } from '../styles';
import { AuthenticationTemplateProps } from '../interfaces';
import {
  HeaderPartial,
  FooterPartial,
  AuthenticationLoginSegment,
  AuthenticationResetSegment,
  AuthenticationPasswordSegment,
} from '../../..';

export const AuthenticationTemplateDefault = (
  props: AuthenticationTemplateProps
) => {
  return (
    <AuthenticationStyled
      data-cy="authentication-layout"
      id={props.id}
      className={props.classes}
    >
      <HeaderPartial {...props.header}></HeaderPartial>

      <main
        role="main"
        className={`authentication-container ${
          props.bannerActive ? 'banner-active' : ''
        }`}
      >
        {props.loginSegmentProps && (
          <div className="authentication-login-content">
            <AuthenticationLoginSegment {...props.loginSegmentProps} />
          </div>
        )}
        {props.resetSegmentProps && (
          <div className="authentication-reset-content">
            <AuthenticationResetSegment {...props.resetSegmentProps} />
          </div>
        )}
        {props.passwordSegmentProps && (
          <div className="authentication-password-content">
            <AuthenticationPasswordSegment {...props.passwordSegmentProps} />
          </div>
        )}
      </main>

      <FooterPartial {...props.footer}></FooterPartial>
    </AuthenticationStyled>
  );
};

import { css } from 'styled-components';
import { StyledTheme, responsive } from '../../../../styles';

export const AuthenticationBaseStyles = css<{ theme: StyledTheme }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.primary.color};

  .authentication-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-top: 0rem;

    ${responsive.desktop.small(css`
      padding-top: 4.5rem;
    `)}

    .authentication-login-content,
    .authentication-reset-content,
    .authentication-password-content {
      width: 100%;
      margin: -10rem auto 0;

      ${responsive.desktop.small(css`
        width: 35%;
        padding: 0 2rem;
        max-width: 34rem;
      `)}

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;

        .authentication-title-content {
          margin-top: 1rem;
          text-align: center;

          ${responsive.desktop.small(css`
            margin-top: 2rem;
          `)}

          .authentication-title-icon {
            display: flex;
            justify-content: center;
            width: 100%;
            color: ${({ theme }) => theme.palette.primaryBackground.color};
            font-size: 3.5rem;
            margin-bottom: 2.5rem;
          }

          h2,
          p {
            font-weight: normal;
            font-family: ${({ theme }) => theme.typography.mainFontFamilyLight};
            margin-top: 1.5rem;
            color: ${({ theme }) => theme.palette.primaryBackground.color};
          }

          h2 {
            margin-top: 0;
            font-size: 2.2rem;
            white-space: nowrap;
            font-family: ${({ theme }) => theme.typography.offsetFontFamily};
          }

          p {
            width: 22rem;
            margin-left: auto;
            margin-right: auto;
            font-size: 1.1rem;
            line-height: 1.7rem;
          }
        }

        .authentication-body-content {
          width: 100%;
          padding: 0 2rem;
          text-align: center;

          ${responsive.desktop.small(css`
            padding: 0;
            max-width: 24rem;
          `)}

          .authentication-server-error,
          .authentication-password-success {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1rem;
            padding: 1rem 0rem 1rem 1rem;
            background-color: ${({ theme }) =>
              theme.palette.primaryError.color};
            color: ${({ theme }) => theme.palette.primaryBackground.color};

            .authentication-server-error-icon,
            .authentication-password-success-icon {
              margin-top: 0.1rem;
              margin-right: 1rem;
              font-size: 1.25rem;
            }

            .authentication-server-error-content,
            .authentication-password-success-content {
              font-family: ${({ theme }) =>
                theme.typography.mainFontFamilyLight};
              text-align: left;

              .authentication-region-link {
                cursor: pointer;
                font-family: ${({ theme }) =>
                  theme.typography.mainFontFamilyMedium};
              }
            }
          }

          .authentication-password-success {
            background-color: ${({ theme }) =>
              theme.palette.successBackground.color};
            color: ${({ theme }) => theme.palette.primary.color};

            .authentication-password-success-content {
              font-family: ${({ theme }) =>
                theme.typography.mainFontFamilyMedium};
            }
          }

          form {
            > div {
              margin-top: 1rem;
              font-size: 3rem;
            }

            > div:first-child {
              margin-top: 0;
            }

            .button-container {
              margin-top: 2rem;
            }
          }

          ${responsive.desktop.small(css`
            form {
              > div {
                margin-top: 2.5rem;
              }

              > div:first-child {
                margin-top: 1.5rem;
              }

              .button-container {
                margin-top: 4rem;
              }
            }
          `)}

          .checkout-registration-submit {
            text-transform: lowercase;
          }
        }

        .authentication-link-content {
          text-align: center;

          a,
          a:active,
          a:visited {
            color: ${({ theme }) => theme.palette.primaryBackground.color};
            margin-top: 10rem;
            font-size: 1.1rem;
          }

          a:hover {
            color: ${({ theme }) => theme.palette.invalidBackground.color};
          }
        }
      }
    }

    .authentication-password-content {
      .authentication-password-title-content {
        font-size: 1.1rem;
        margin-top: 1rem;

        span {
          color: ${({ theme }) => theme.palette.invalidBackground.color};
        }
      }

      .authentication-login-content {
        text-align: center;

        a {
          font-size: 1.1rem;
          color: ${({ theme }) => theme.palette.primaryBackground.color};
        }

        a:hover {
          color: ${({ theme }) => theme.palette.invalidBackground.color};
        }
      }
    }
  }
`;

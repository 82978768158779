import { useOnClickEffect } from '../../../../hooks';
import {
  AuthenticationLoginProps,
  AuthenticationLoginState,
  AuthenticationLoginHandlers,
} from '../../..';

export function useAuthenticationLoginEffect(
  props: AuthenticationLoginProps
): [AuthenticationLoginState, AuthenticationLoginHandlers] {
  const classes = props.styleClasses.join(' ');

  const state: AuthenticationLoginState = {
    classes,
  };

  const [onForgotPassword] = useOnClickEffect({
    id: props.id,
    name: props.id,
    onClickHandler: props.onForgotPasswordHandler,
    preventDefault: true,
  });

  const handlers: AuthenticationLoginHandlers = {
    onForgotPassword,
  };

  return [state, handlers];
}

import {
  AuthenticationProps,
  AuthenticationTemplateProps,
  AuthenticationTemplateDefault,
  useAuthenticationEffect,
} from '../../..';

export const AuthenticationLayout = (props: AuthenticationProps) => {
  const [state] = useAuthenticationEffect(props);

  const templateProps: AuthenticationTemplateProps = {
    id: props.id,
    display: props.display,
    header: props.header,
    footer: props.footer,
    loginSegmentProps: props.loginSegmentProps,
    resetSegmentProps: props.resetSegmentProps,
    passwordSegmentProps: props.passwordSegmentProps,
    bannerActive: props.bannerActive,
    ...state,
  };

  let template;
  switch (props.template) {
    case 'authenticationDefault':
    default:
      template = AuthenticationTemplateDefault(templateProps);
      break;
  }

  return template;
};

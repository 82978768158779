import { css } from 'styled-components';
import { StyledTheme } from '../../../../styles';

export const AuthenticationPasswordBaseStyles = css<{ theme: StyledTheme }>`
  .authentication-password-error-title {
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.palette.primaryBackground.color};
      font-size: 3rem;
    }
  }

  .authentication-password-error-body {
    margin-top: 2rem;
  }
`;

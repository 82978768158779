import { FormInputValue } from '..';
import {
  FormInputValidationResult,
  FormInputValidationRules,
} from '../interfaces';

export const requiredValidator = async (
  value: FormInputValue,
  props?: FormInputValidationRules
): Promise<FormInputValidationResult> => {
  if (!props?.required || value) {
    return { valid: true, errors: [] };
  }

  return { valid: false, errors: ['Required'] };
};

import {
  useAuthenticationResetEffect,
  AuthenticationResetProps,
  AuthenticationResetTemplateProps,
  AuthenticationResetDefaultTemplate,
} from '../../..';

export const AuthenticationResetSegment = (props: AuthenticationResetProps) => {
  const [state, handlers] = useAuthenticationResetEffect(props);

  const templateProps: AuthenticationResetTemplateProps = {
    id: props.id,
    form: props.form,
    isLoading: props.isLoading,
    sent: props.sent,
    targetEmail: props.targetEmail,
    loginButtonProps: props.loginButtonProps,
    onHomeHandler: props.onHomeHandler,
    ...state,
    ...handlers,
  };

  let template;
  switch (props.template) {
    case 'authenticationResetDefault':
    default:
      template = AuthenticationResetDefaultTemplate(templateProps);
      break;
  }

  return template;
};

import React from 'react';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';
import {
  ButtonInputElement,
  IconElement,
  TextInputElement,
} from '../../../elements';
import { AuthenticationPasswordTemplateProps } from '../interfaces';
import { AuthenticationPasswordStyled } from '../styles';

export const AuthenticationPasswordDefaultTemplate = (
  props: AuthenticationPasswordTemplateProps
) => {
  if (!props.form) {
    throw new UnacceptableValueException(
      'Could not load authentication password template: Missing form'
    );
  }

  if (!props.newLinkButtonProps) {
    throw new UnacceptableValueException(
      'Could not load authentication password template: Missing new link button props'
    );
  }

  return (
    <AuthenticationPasswordStyled className={props.classes}>
      {!props.serverError ? (
        <>
          <div className="authentication-title-content">
            <h2>New password</h2>
            <p className="authentication-password-title-content">
              Create a strong password by using a mix of letters, numbers and
              symbols to keep your account safe.
            </p>
          </div>
          <div className="authentication-body-content">
            <form>
              <TextInputElement {...props.form.passwordInputProps} />
              <TextInputElement {...props.form.confirmInputProps} />
              <div className="button-container">
                <ButtonInputElement {...props.form.submitInputProps} />
              </div>
            </form>
          </div>
          <p className="authentication-link-content">
            <a onClick={props.onLogin}>Go to login page</a>
          </p>
        </>
      ) : (
        <>
          <div className="authentication-title-content authentication-password-error-title">
            <IconElement
              template="iconExclamation"
              styleClasses={['iconBase']}
            />
            <h2>This link is expired</h2>
            <p className="authentication-password-title-content">
              Please request a new link to reset password.
            </p>
          </div>
          <div className="authentication-body-content authentication-password-error-body">
            <div className="button-container">
              <ButtonInputElement {...props.newLinkButtonProps} />
            </div>
          </div>
          <p className="authentication-link-content">
            <a onClick={props.onLogin}>Go to login page</a>
          </p>
        </>
      )}
    </AuthenticationPasswordStyled>
  );
};

import React from 'react';
import { UnacceptableValueException } from '@hiwaldo/sdk/exceptions';
import {
  ButtonInputElement,
  IconElement,
  TextInputElement,
} from '../../../elements';
import { AuthenticationResetTemplateProps } from '../interfaces';
import { AuthenticationResetStyled } from '../styles';

export const AuthenticationResetDefaultTemplate = (
  props: AuthenticationResetTemplateProps
) => {
  if (!props.form) {
    throw new UnacceptableValueException(
      'Could not load authentication reset template: Missing form'
    );
  }

  return (
    <AuthenticationResetStyled className={props.classes}>
      {!props.sent ? (
        <>
          <div className="authentication-title-content">
            <h2>Forgot password?</h2>
            <p>
              Enter your email and we will send instructions to your inbox to
              reset your password.
            </p>
          </div>
          <div className="authentication-body-content">
            <form>
              <div className="text-input-container">
                <TextInputElement {...props.form.emailInputProps} />
              </div>
              <div className="button-container">
                <ButtonInputElement {...props.form.submitInputProps} />
              </div>
            </form>
          </div>
          <p className="authentication-link-content">
            <a onClick={props.onLogin}>Go to login page</a>
          </p>
        </>
      ) : (
        <>
          <div className="authentication-title-content">
            <div className="authentication-title-icon">
              <IconElement
                template="iconCheckEmpty"
                styleClasses={['iconBase']}
              />
            </div>
            <h2>Email sent</h2>
            <p>
              An email has been sent to {props.targetEmail}. If you do not
              receive an email, please make sure you have entered an email
              address registered with this site.
            </p>
          </div>
          <div className="authentication-body-content">
            <form>
              <div className="button-container">
                <ButtonInputElement {...props.loginButtonProps} />
              </div>
            </form>
          </div>
          <p className="authentication-link-content">
            <a onClick={props.onHomeHandler}>Go to homepage</a>
          </p>
        </>
      )}
    </AuthenticationResetStyled>
  );
};

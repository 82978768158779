import {
  useAuthenticationPasswordEffect,
  AuthenticationPasswordProps,
  AuthenticationPasswordTemplateProps,
  AuthenticationPasswordDefaultTemplate,
} from '../../..';

export const AuthenticationPasswordSegment = (
  props: AuthenticationPasswordProps
) => {
  const [state, handlers] = useAuthenticationPasswordEffect(props);

  const templateProps: AuthenticationPasswordTemplateProps = {
    id: props.id,
    form: props.form,
    newLinkButtonProps: props.newLinkButtonProps,
    isLoading: props.isLoading,
    serverError: props.serverError,
    ...state,
    ...handlers,
  };

  let template;
  switch (props.template) {
    case 'authenticationPasswordDefault':
    default:
      template = AuthenticationPasswordDefaultTemplate(templateProps);
      break;
  }

  return template;
};
